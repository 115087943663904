import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectQuery, queryOptions } from '@ngneat/query';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentPage } from './interfaces/cms.models';
import { BlogPost } from './interfaces/blog-post';
import { CmsDownload } from './interfaces/cms-download';
import { CmsFooterContent } from './cms.interface';
import { TranslocoService } from '@jsverse/transloco';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class CmsService {
  private _http = inject(HttpClient);
  private _query = injectQuery();

  private translocoService = inject(TranslocoService);
  private activeLanguage = toSignal(this.translocoService.langChanges$);

  getMenu() {
    return this._query({
      queryKey: ['cms-menu'] as const,
      queryFn: () => this._http.get<CmsUiDynamicContentMenu>(`${environment.cms.apiUrl}/${environment.cms.cmsMainMenu}?locale=${this.activeLanguage()}`),
    });
  }

  getHomePage() {
    return this._query({
      queryKey: ['home-page'] as const,
      queryFn: (): Observable<CmsUiDynamicContentPage> =>
        this._http.get<CmsUiDynamicContentPage>(`${environment.cms.apiUrl}/${environment.cms.homepage}?locale=${this.activeLanguage()}`),
    });
  }

  getFooter() {
    return this._query({
      queryKey: ['footer'] as const,
      queryFn: (): Observable<CmsFooterContent> =>
        this._http.get<CmsFooterContent>(`${environment.cms.apiUrl}/${environment.cms.footer}?locale=${this.activeLanguage()}`),
    });
  }

  getErrorPage() {
    return this._query({
      queryKey: ['error-page'] as const,
      queryFn: (): Observable<CmsUiDynamicContentPage> =>
        this._http.get<CmsUiDynamicContentPage>(`${environment.cms.apiUrl}/${environment.cms.error}?locale=${this.activeLanguage()}`),
    });
  }

  getDownloads() {
    return this._query({
      queryKey: ['downloads'] as const,
      queryFn: () => this._http.get<CmsDownload[]>(`${environment.cms.apiUrl}/downloads?locale=${this.activeLanguage()}`),
    });
  }

  getBlogPosts() {
    return this._query({
      queryKey: ['blog-posts'] as const,
      queryFn: () => this._http.get<BlogPost[]>(`${environment.cms.apiUrl}/posts?locale=${this.activeLanguage()}`),
    });
  }

  getBlogPost(id: number) {
    return this._query({
      queryKey: ['blog-post', id] as const,
      queryFn: () => this._http.get<BlogPost>(`${environment.cms.apiUrl}/posts/${id}?locale=${this.activeLanguage()}`),
    });
  }

  getPageOptions(id: string) {
    return queryOptions({
      queryKey: ['cms-page', id] as const,
      queryFn: (): Observable<CmsUiDynamicContentPage> =>
        this._http.get<CmsUiDynamicContentPage>(`${environment.cms.apiUrl}/pages/${id}?locale=${this.activeLanguage()}`),
    });
  }

  getPage(id: string) {
    return this._query(this.getPageOptions(id));
  }
}
